<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >
      <b-row
        class="border-bottom"
      >
        <b-col
          cols="12"
          md="6"
          offset-md="6"
        >
          <ValidationObserver
            ref="formSynchronizer"
            v-slot="{ errors: formErrors }"
          >
            <b-row>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="start_date"
                  name="start date"
                  rules="required"
                >
                  <b-form-group
                    label="Start Date"
                    label-for="synchronizeStartDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeStartDate"
                      v-model="synchronize.start_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :max="synchronize.end_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="end_date"
                  name="end date"
                  rules="required"
                >

                  <b-form-group
                    label="End Date"
                    label-for="synchronizeEndDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeEndDate"
                      v-model="synchronize.end_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :min="synchronize.start_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1 pb-2 d-flex align-items-end"
              >
                <b-container
                  fluid
                >
                  <b-row>
                    <b-col
                      cols="6"
                      class="pr-1 pl-0"
                    >
                      <b-overlay
                        :show="state.synchronizing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="success"
                        class="p-0"
                      >
                        <b-button
                          :variant="`${state.synchronizing ? 'outline-dark' : 'success'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="synchronizeWarehouses"
                        >
                          Sync
                        </b-button>
                      </b-overlay>
                    </b-col>
                    <b-col
                      cols="6"
                      class="px-0"
                    >
                      <b-overlay
                        :show="state.importing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="warning"
                        :class="[
                          'p-0', {
                            'mb-3 pb-1': (
                              Object.keys(formErrors).filter(
                                error => formErrors[error]?.length > 0
                              ).length > 0
                            )
                          }
                        ]"
                      >
                        <b-button
                          :variant="`${state.importing ? 'outline-dark' : 'warning'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="importWarehouses"
                        >
                          Import
                        </b-button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>

      </b-row>

      <b-row
        class="mt-2"
      >

        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterWarehouseSearch"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filterWarehouseSearch"
              v-model="tableWarehouses.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableWarehouses"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableWarehouses.busy"
            :filter="tableWarehouses.filter"
            :fields="tableWarehouses.fields"
            :stacked="tableWarehouses.stacked"
            :sort-by.sync="tableWarehouses.sortBy"
            :sort-desc.sync="tableWarehouses.sortDesc"
            :sort-direction="tableWarehouses.sortDirection"
            :filter-included-fields="tableWarehouses.filterOn"
            :current-page="tableWarehouses.currentPage"
            :per-page="tableWarehouses.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableWarehouses, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  v-if="Number(item.active) === 1"
                  size="sm"
                  variant="danger"
                  @click.prevent="() => updateWarehouse(item, 0)"
                >
                  Deactivate
                </b-button>
                <b-button
                  v-else
                  size="sm"
                  variant="success"
                  @click.prevent="() => updateWarehouse(item, 1)"
                >
                  Activate
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableWarehouses.perPage"
            :options="tableWarehouses.pageOptions"
            :disabled="tableWarehouses.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableWarehouses.currentPage"
            :total-rows="tableWarehouses.totalRows"
            :per-page="tableWarehouses.perPage"
            :disabled="tableWarehouses.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SAdminWarehouse } from '@/services'

export default {
  name: 'AdminWarehouses',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Warehouses'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        synchronizing: false,
        importing: false
      },
      synchronize: {
        start_date: null,
        end_date: null
      },
      warehouse: {
        id: null,
        warehouse_code: null,
        warehouse_name: null,
        active: 1
      },
      tableWarehouses: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 7, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'warehouse_code', class: 'min-w-175px' },
          { mobile: 3, key: 'warehouse_name', class: 'min-w-175px' },
          { mobile: 4, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 5, key: 'valid', class: 'text-center' },
          { mobile: 6, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalWarehouseTitle () {
      return this.state.editing ? 'Edit Warehouse' : 'Add Warehouse'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableWarehouses.busy = true
      return await SAdminWarehouse.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tableWarehouses.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableWarehouses.totalRows = 0
        return []
      }).finally(() => {
        this.tableWarehouses.busy = false
      })
    },

    async synchronizeWarehouses () {
      await this.$refs.formSynchronizer.validate().then(
        validSynchronizer => {
          if (!validSynchronizer) {
            return this.swalInvalid()
          }

          this.state.synchronizing = true
          return SAdminWarehouse.sync(this.synchronize).then(({ data: { message } }) => {
            this.swalSuccess(message).then(() => {
              this.synchronize.start_date = null
              this.synchronize.end_date = null
              this.$refs.formSynchronizer.reset()
              this.$refs.tableWarehouses.refresh()
            })
          }).finally(() => {
            this.state.synchronizing = false
          })
        }
      )
    },

    importWarehouses () {
      this.state.importing = true
      return SAdminWarehouse.import().then(({ data: { message } }) => {
        this.swalSuccess(message).then(() => {
          this.$refs.tableWarehouses.refresh()
        })
      }).finally(() => {
        this.state.importing = false
      })
    },

    createWarehouse () {
      this.state.editing = false
      this.warehouse.id = null
      this.warehouse.warehouse_code = null
      this.warehouse.warehouse_name = null
      this.warehouse.active = 1

      this.$bvModal.show('modalWarehouse')
    },

    editWarehouse (warehouse) {
      this.state.editing = true
      this.warehouse.id = warehouse.id
      this.warehouse.warehouse_code = warehouse.warehouse_code
      this.warehouse.warehouse_name = warehouse.warehouse_name
      this.warehouse.active = warehouse.active

      this.$bvModal.show('modalWarehouse')
    },

    async updateWarehouse (warehouse, active = 1) {
      this.swalConfirm({
        html: () => (`
          <div>
            <div class="pb-5">${Number(active) === 1 ? 'Activate' : 'Deactivate'} the warehouse?</div>
            <div class="h6 font-weight-bolder">${warehouse.warehouse_name}</div>
            <small>${warehouse.warehouse_code}</small>
          </div>
        `),
        confirmButtonText: (
          Number(active) === 1 ? 'Activate' : 'Deactivate'
        ),
        customClass: {
          confirmButton: (
            Number(active) === 1 ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'
          )
        },
        preConfirm: () => {
          this.busy = true
          return SAdminWarehouse.put({
            id: warehouse.id,
            active: active
          }).then(
            ({ data: { message, warehouse } }) => {
              this.swalSuccess(message).then(() => {
                const tableWarehousesRow = QUERY.find(this.$refs.tableWarehouses.localItems, {
                  id: Number(warehouse.id)
                })
                if (tableWarehousesRow) {
                  tableWarehousesRow.warehouse_code = warehouse.warehouse_code
                  tableWarehousesRow.warehouse_name = warehouse.warehouse_name
                  tableWarehousesRow.active = warehouse.active
                  tableWarehousesRow.valid = warehouse.valid
                  tableWarehousesRow.updated_at = warehouse.updated_at
                }
              })
            }
          ).finally(() => {
            this.busy = false
          })
        }
      })
    }
  }
}
</script>
